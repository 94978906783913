import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import UserContext from "../context/user";
import {
  getProduct,
  getProdutcsByTagAndCategories,
  getImportantProducts,
  getNewProdcut,
  getHomeProductsByUserId,
  getProductsByUserId,
  addCourse,
  renameCourse,
  enrollCourse,
  deleteCourse,
  activationLink,
} from "../utils/products";
import { getTypeAndSubjects, search } from "../utils/search";
import {
  getProvince,
  getUniversity,
  getConsultant,
  addConsultantContact,
  productActivation,
} from "../utils/user";
import {
  getListAddressByUserId,
  addAdrress,
  updateAdrress,
  addCopyRequest,
  deleteAdrress,
} from "../utils/address";
import { getBacheca, getHeaderMenu, getFooterMenu } from "../utils/webcontet";
import { SessionExpiredError } from "../utils/call-api";

export const useProduct = (operationName: string, productId: string) => {
  return useQuery({
    queryKey: ["productDetail", productId],
    queryFn: () => getProduct(operationName, productId),
    select: (data) => {
      return data.pearsonProductById || data.pearsonCheckIsbnProduct;
    },
    enabled: !!productId,
  });
};

export const useProducts = (operationName: string, type: string, size: number) => {
  return useInfiniteQuery({
    queryKey: [operationName, "", type, size],
    queryFn: getProdutcsByTagAndCategories,
    getNextPageParam: (lastPage, pages) => {
      const { totalCount, pageSize, page } = lastPage.pearsonProducts;
      if (totalCount > pageSize * page) {
        return page + 1;
      } else {
        return undefined;
      }
    },
  });
};

export const useNewProducts = (operationName: string, type: string, size: number) => {
  return useInfiniteQuery({
    queryKey: [operationName, type, size],
    queryFn: getNewProdcut,
    getNextPageParam: (lastPage, pages) => {
      const { totalCount, pageSize, page } = lastPage.pearsonProducts;
      if (totalCount > pageSize * page) {
        return page + 1;
      } else {
        return undefined;
      }
    },
  });
};

export const useSlides = (operationName: string, type: string, size: number) => {
  return useQuery({
    queryKey: [operationName, type, size],
    queryFn: getImportantProducts,
    select: (data) => {
      return data;
    },
  });
};

export const useSubject = () => {
  return useQuery({
    queryKey: ["getSubjects"],
    queryFn: () => getTypeAndSubjects("getSubjectsCategories"),
    select: (data) => {
      let mappedSubjects = data.subject?.items.map((el: category) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
      mappedSubjects = [
        {
          label: "Tutte le materie",
          value: "",
        },
      ].concat(mappedSubjects);

      let mappedTypes = data.ptype?.items.map((el: category) => {
        return {
          label: el.name,
          value: el.name,
        };
      });
      mappedTypes = [
        {
          label: "Tutti i prodotti",
          value: "",
        },
      ].concat(mappedTypes);
      return { mappedSubjects, mappedTypes };
    },
  });
};

export const useSearch = (wrapSearchParams: SearchParam) => {
  return useInfiniteQuery({
    queryKey: ["search", wrapSearchParams],
    queryFn: search,
    getNextPageParam: (lastPage, pages) => {
      const { totalCount, pageSize, page } = lastPage.pearsonProducts;
      if (totalCount > pageSize * page) {
        return page + 1;
      } else {
        return undefined;
      }
    },
    enabled: !!wrapSearchParams,
    //staleTime: 60 * 1000,
  });
};

export const useProvince = () => {
  return useQuery({
    queryKey: ["getProvince"],
    queryFn: getProvince,
    select: (data) => {
      return data.items;
    },
  });
};

export const useUniversity = (province: string) => {
  return useQuery({
    queryKey: ["getUniversity", province],
    queryFn: () => getUniversity(province),
    select: (data) => {
      return data.items;
    },
    enabled: !!province,
  });
};

export const useAdresses = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;

  return useQuery({
    queryKey: ["getAddressesByUserId", userId],
    queryFn: () => getListAddressByUserId("getAddressesByUserId", userId),
    select: (data) => {
      return data.pearsonUserAddressByUserId.items;
    },
    enabled: !!user,
    staleTime: 0,
  });
};

export const useAddAddress = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ address }: { address: Address }) => addAdrress(userId, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAddressesByUserId"] });
    },
  });
};

const handleError = (sessionExpired: () => void) => (err: any) => {
  if (err instanceof SessionExpiredError) {
    sessionExpired();
  }
};

export const useProductActivation = () => {
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ codeId }: { codeId: string }) =>
      productActivation(codeId, userData as UserDataForQuery),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
    },
    onError: handleError(sessionExpired),
  });
};

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  return useMutation({
    mutationFn: ({ address }: { address: Address }) => updateAdrress(userId, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAddressesByUserId"] });
    },
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  return useMutation({
    mutationFn: ({ address }: { address: Address }) => deleteAdrress(userId, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAddressesByUserId"] });
    },
  });
};

export const useUserProduct = (size?: number) => {
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();
  return useQuery({
    queryKey: ["getProductsByUserId", userData, size],
    queryFn: () =>
      getHomeProductsByUserId("getProductsByUserId", userData as UserDataForQuery, size),
    select: (data) => {
      const myProducts = data.prodotti;
      const myWebinars = data.webinar;
      const myLearning = data.formazione;
      return { myProducts, myWebinars, myLearning };
    },
    onError: handleError(sessionExpired),
    enabled: !!userData?.userId && !!userData?.userRole,
  });
};

export const useUserProductByCategory = (
  operationName: string,
  category: string,
  size?: number
) => {
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();
  return useInfiniteQuery({
    queryKey: [operationName, userData, category, size],
    queryFn: getProductsByUserId,
    getNextPageParam: (lastPage, pages) => {
      const { totalCount, pageSize, page } = lastPage.pearsonProductsByUserId;
      if (totalCount > pageSize * page) {
        return page + 1;
      } else {
        return undefined;
      }
    },
    onError: handleError(sessionExpired),
    enabled: !!userData?.userId,
  });
};

/* return useInfiniteQuery({
  queryKey: [operationName, type, size],
  queryFn: getNewProdcut,
  getNextPageParam: (lastPage, pages) => {
    const { totalCount, pageSize, page } = lastPage.pearsonProducts;
    if (totalCount > pageSize * page) {
      return page + 1;
    } else {
      return undefined;
    }
  },
}); */

export const useAddCourse = () => {
  const queryClient = useQueryClient();
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();

  return useMutation({
    mutationFn: ({
      productId,
      moodleId,
      courseName,
    }: {
      productId: number;
      moodleId: number;
      courseName: string;
    }) => addCourse(userData as UserDataForQuery, productId, moodleId, courseName),
    onSuccess: (data) => {
      console.log("useAddCourse", data);
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
      return data;
    },
    onError: handleError(sessionExpired),
  });
};

export const useActivationLink = () => {
  const queryClient = useQueryClient();
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();

  return useMutation({
    mutationFn: ({ productId }: { productId: number }) =>
      activationLink(userData as UserDataForQuery, productId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
    },
    onError: handleError(sessionExpired),
  });
};

export const useRenameCourse = () => {
  const queryClient = useQueryClient();
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();

  return useMutation({
    mutationFn: ({ courseId, courseName }: { courseId: number; courseName: string }) =>
      renameCourse(userData as UserDataForQuery, courseId, courseName),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
      return data;
    },
    onError: handleError(sessionExpired),
  });
};

export const useEnrollCourse = () => {
  const queryClient = useQueryClient();
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();

  return useMutation({
    mutationFn: ({ productId, code }: { productId: number; code: number }) =>
      enrollCourse(userData as UserDataForQuery, productId, code),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
      return data;
    },
    onError: handleError(sessionExpired),
  });
};

export const useAddCopyRequest = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  return useMutation({
    mutationFn: ({ formData }: { formData: any }) => addCopyRequest(userId, formData),
    onSuccess: () => {
      console.log("add copy request");
    },
  });
};

export const useBacheca = (role: string) => {
  return useQuery({
    queryKey: ["getBacheca", role],
    queryFn: () => getBacheca("contentStructureStructuredContents"),
    select: (data) => {
      const items = data.contentStructureStructuredContents.items;
      const notifiche = items.map((item: any) => {
        return {
          title: item.contentFields.find((elem: any) => elem.name === "title").contentFieldValue
            .data,
          description: item.contentFields.find((elem: any) => elem.name === "description")
            .contentFieldValue.data,
          image: item.contentFields.find((elem: any) => elem.name === "image")?.contentFieldValue
            ?.image.contentUrl,
          buttonLink: item.contentFields.find((elem: any) => elem.name === "link").contentFieldValue
            .data,
          datePublished: item.datePublished,
        };
      });
      //console.log("notifiche", notifiche);
      return notifiche;
    },
    enabled: !!role,
    staleTime: 0,
  });
};

export const useHeaderMenu = () => {
  return useQuery({
    queryKey: ["getHeaderMenu"],
    queryFn: () => getHeaderMenu("structuredContent"),
    select: (data) => {
      const fields = data.structuredContent.contentFields;
      const menu = fields.map((item: any) => {
        return {
          label: item.nestedContentFields.find((i: any) => i.name === "voce")?.contentFieldValue
            ?.data,
          value: item.nestedContentFields.find((i: any) => i.name === "visualizza")
            ?.contentFieldValue?.data,
        };
      });
      return menu;
    },
  });
};

export const useFooterMenu = () => {
  return useQuery({
    queryKey: ["getFooterMenu"],
    queryFn: () => getFooterMenu("structuredContent"),
  });
};

export const useConsultant = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  return useQuery({
    queryKey: ["getConsultant", userId],
    queryFn: () => getConsultant(userId),
    select: (data) => {
      return data.pearsonConsultantByUserId;
    },
    staleTime: 0,
    enabled: !!user,
  });
};

export const useAddConsultantContact = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId as string;
  return useMutation({
    mutationFn: ({ messageResult }: { messageResult: string }) =>
      addConsultantContact(userId, messageResult),
    onSuccess: () => {
      console.log("message sent");
    },
  });
};

export const useDeleteCourse = () => {
  const queryClient = useQueryClient();
  const { getUserDataForQuery, sessionExpired } = useContext(UserContext);
  const userData = getUserDataForQuery();

  return useMutation({
    mutationFn: ({ courseId }: { courseId: number }) =>
      deleteCourse(userData as UserDataForQuery, courseId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getProductsByUserId"] });
      return data;
    },
    onError: handleError(sessionExpired),
  });
};
